/*
 * Unpublished work. Copyright 2025 Siemens
 *
 * This material contains trade secrets or otherwise confidential information
 * owned by Siemens Industry Software Inc. or its affiliates (collectively,
 * "SISW"), or its licensors. Access to and use of this information is strictly
 * limited as set forth in the Customer's applicable agreements with SISW.
 */
import React from 'react';
import { useAppViewPort } from 'webComponent/hooks';

import { Checkbox } from 'components/atoms/Checkbox';
import { CheckboxLabel } from 'components/atoms/CheckboxLabel';
import { NormalInput } from 'components/atoms/Input';
import { Label } from 'components/atoms/Label';
import { Dropdown, DropdownCheckItem, DropdownSelectItem } from 'components/molecules/Dropdown';

const testIds = {
    main: 'supply-chain-filtering-form-main',
    grid: 'supply-chain-filtering-form-grid',
    partDistributor: 'supply-chain-filtering-form-part-distributor',
    desiredStock: 'supply-chain-filtering-form-desired-stock',
    currency: 'supply-chain-filtering-form-currency',
    manufacturer: 'supply-chain-filtering-form-manufacturer',
    inStockOnly: 'supply-chain-filtering-form-in-stock-only',
    exactMatchesOnly: 'supply-chain-filtering-form-exact-matches-only',
    partDistributorArea: 'supply-chain-filtering-form-part-distributor-area',
    bottomRowArea: 'supply-chain-filtering-form-bottom-row-area',
};

export { testIds as supplyChainFilteringFormTestIds };

export interface ISupplyChainFilteringState {
    partDistributors: Array<number>;
    desiredStock?: number;
    currency?: string;
    manufacturer?: string;
    inStockOnly: boolean;
    exactMatchesOnly: boolean;
}

export const SupplyChainFilteringForm = ({
    partDistributors,
    currencies,
    manufacturers,
    filters,
    setFilters,
    ...props
}: {
    partDistributors: Array<{ id: number; name?: string }>;
    currencies: Array<string>;
    manufacturers: Array<string>;
    filters: ISupplyChainFilteringState;
    setFilters: React.Dispatch<React.SetStateAction<ISupplyChainFilteringState>>;
} & Omit<React.HTMLAttributes<HTMLDivElement>, 'children' | 'classname'>) => {
    const [partDistributorSearch, setPartDistributorSearch] = React.useState('');
    const { isSm, isMd, isLg } = useAppViewPort();

    return (
        <div data-testid={testIds.main} {...props} className='bg-background-mid px-4 pb-4 pt-2'>
            <div
                data-testid={testIds.grid}
                className={`grid gap-2 ${isSm ? 'grid-cols-[100px_60px_1fr]' : ''} ${isMd ? 'grid-cols-[1fr_100px_60px_160px]' : ''} ${isLg ? 'grid-cols-[1fr_100px_60px_248px]' : ''}`}
            >
                <div
                    data-testid={testIds.partDistributorArea}
                    className={`${isSm ? 'col-span-3' : ''}`}
                >
                    <Label>Part Distributor</Label>
                    <Dropdown
                        id={testIds.partDistributor}
                        data-testid={testIds.partDistributor}
                        enableSearch={true}
                        searchValue={partDistributorSearch}
                        onSearchValueChange={(e) => setPartDistributorSearch(e.target.value)}
                        onClose={() => setPartDistributorSearch('')}
                        render={
                            filters.partDistributors.length === partDistributors.length
                                ? 'All available'
                                : partDistributors
                                      .filter((pd) => filters.partDistributors.includes(pd.id))
                                      .map((pd) => pd.name)
                                      .join(', ')
                        }
                    >
                        {partDistributors
                            .filter((d) =>
                                d.name?.toLowerCase().includes(partDistributorSearch.toLowerCase()),
                            )
                            .map(({ id, name }) => (
                                <DropdownCheckItem
                                    key={id}
                                    checked={filters.partDistributors.includes(id)}
                                    onChange={() => {
                                        setFilters({
                                            ...filters,
                                            partDistributors: filters.partDistributors.includes(id)
                                                ? filters.partDistributors.filter((pd) => pd !== id)
                                                : [...filters.partDistributors, id],
                                        });
                                    }}
                                >
                                    {name}
                                </DropdownCheckItem>
                            ))}
                    </Dropdown>
                </div>

                <div>
                    <Label>Desired Stock</Label>

                    <NormalInput
                        id={testIds.desiredStock}
                        data-testid={testIds.desiredStock}
                        value={filters.desiredStock ?? ''}
                        onKeyDown={(e) => /^[.\-+Ee]$/.test(e.key) && e.preventDefault()}
                        onChange={(e) => {
                            let value: number | undefined = Number.parseInt(e.target.value);

                            if (Number.isNaN(value)) {
                                value = undefined;
                            } else if (value < 0 || value > Number.MAX_SAFE_INTEGER) {
                                value = filters.desiredStock;
                            }

                            setFilters({
                                ...filters,
                                desiredStock: value,
                            });
                        }}
                        onPaste={(e) => e.preventDefault()}
                        type='number'
                        placeholder='eg. 2000'
                    />
                </div>

                <div>
                    <Label>Currency</Label>
                    <Dropdown
                        id={testIds.currency}
                        data-testid={testIds.currency}
                        render={filters.currency}
                    >
                        <DropdownSelectItem
                            onClick={() => setFilters({ ...filters, currency: undefined })}
                        />

                        {currencies.map((currency) => (
                            <DropdownSelectItem
                                key={currency}
                                selected={filters.currency === currency}
                                onClick={() => setFilters({ ...filters, currency })}
                            >
                                {currency}
                            </DropdownSelectItem>
                        ))}
                    </Dropdown>
                </div>

                <div>
                    <Label>Manufacturer</Label>
                    <Dropdown
                        id={testIds.manufacturer}
                        data-testid={testIds.manufacturer}
                        render={filters.manufacturer}
                    >
                        <DropdownSelectItem
                            onClick={() =>
                                setFilters({
                                    ...filters,
                                    manufacturer: undefined,
                                })
                            }
                        />

                        {manufacturers.map((manufacturer) => (
                            <DropdownSelectItem
                                key={manufacturer}
                                onClick={() => setFilters({ ...filters, manufacturer })}
                            >
                                {manufacturer}
                            </DropdownSelectItem>
                        ))}
                    </Dropdown>
                </div>
                <div
                    data-testid={testIds.bottomRowArea}
                    className={`flex ${isSm ? 'col-span-3' : ''} ${isMd ? 'col-span-4' : ''} ${isLg ? 'col-span-4' : ''}`}
                >
                    <div className='flex items-center'>
                        <Checkbox
                            data-testid={testIds.inStockOnly}
                            checked={filters.inStockOnly}
                            onChange={() =>
                                setFilters({
                                    ...filters,
                                    inStockOnly: !filters.inStockOnly,
                                })
                            }
                        />
                        <CheckboxLabel className='ml-1'>In Stock Only</CheckboxLabel>
                    </div>

                    <div className='ml-4 flex items-center'>
                        <Checkbox
                            data-testid={testIds.exactMatchesOnly}
                            checked={filters.exactMatchesOnly}
                            onChange={() =>
                                setFilters({
                                    ...filters,
                                    exactMatchesOnly: !filters.exactMatchesOnly,
                                })
                            }
                        />
                        <CheckboxLabel>Exact Matches Only</CheckboxLabel>
                    </div>
                </div>
            </div>
        </div>
    );
};
