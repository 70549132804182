/*
 * Unpublished work. Copyright 2025 Siemens
 *
 * This material contains trade secrets or otherwise confidential information
 * owned by Siemens Industry Software Inc. or its affiliates (collectively,
 * "SISW"), or its licensors. Access to and use of this information is strictly
 * limited as set forth in the Customer's applicable agreements with SISW.
 */

// Development
export const isLocalDevelopment = import.meta.env.MODE === 'development';
export const isMockingActive = import.meta.env.VITE_MOCKS === 'true';

// Environment
export const environment = (import.meta.env.VITE_ENVIRONMENT as string) || '';
export const isProduction = environment.toLowerCase() === 'production';

// Version
export const productVersion = (import.meta.env.VITE_PRODUCT_VERSION as string) || '';
export const buildVersion =
    (import.meta.env.VITE_COMMIT_SHORT_SHA as string) || `${Math.floor(Date.now() / 1000)}`;
export const fullProductVersion = `${productVersion}-${buildVersion}`;
export const displayedVersion = isProduction ? productVersion : fullProductVersion;

// Urls
export const frontendRoot = (import.meta.env.VITE_FRONTEND_ROOT as string) || '';
export const apiRegionMap: Record<string, string> = JSON.parse(
    import.meta.env.VITE_API_REGION_MAP || '{}',
);
export const pQPortalRegionMap: Record<string, string> = JSON.parse(
    import.meta.env.VITE_PQPORTAL_REGION_MAP || '{}',
);

// Analytics
export const sanProductKey = (import.meta.env.VITE_SAN_PRODUCT_KEY as string) || '';
export const sanInternalUsage = (import.meta.env.VITE_SAN_INTERNAL_USAGE as string) === 'true';

// Grid
export const gridPagerSize = 25;

// Legacy stuff
export const isLegacyBrowser = true;
