/*
 * Unpublished work. Copyright 2025 Siemens
 *
 * This material contains trade secrets or otherwise confidential information
 * owned by Siemens Industry Software Inc. or its affiliates (collectively,
 * "SISW"), or its licensors. Access to and use of this information is strictly
 * limited as set forth in the Customer's applicable agreements with SISW.
 */
import type React from 'react';
import { useEffect, useRef, useState } from 'react';

export type TooltipPlacementType =
    | 'top'
    | 'bottom'
    | 'left-top'
    | 'left-center'
    | 'left-bottom'
    | 'right-top'
    | 'right-center'
    | 'right-bottom';

export type TooltipTriggerType = 'hover' | 'click';

interface TooltipProps {
    content: React.ReactNode;
    placement?: TooltipPlacementType;
    verticalOffsetValue?: number;
    trigger?: TooltipTriggerType;
    className?: string;
    children?: React.ReactNode;
}

const testIds = {
    main: 'tooltip-main',
    arrow: 'tooltip-arrow',
    arrowBox: 'tooltip-arrow-box',
    trigger: 'tooltip-trigger',
    content: 'tooltip-content',
} as const;

export { testIds as TooltipIds };

export const Tooltip: React.FC<TooltipProps> = ({
    content,
    placement = 'top',
    verticalOffsetValue = 0,
    trigger = 'hover',
    className = 'bg-background-mid px-2 shadow-tooltip',
    children,
}) => {
    const [isVisible, setIsVisible] = useState(false);
    const tooltipRef = useRef<HTMLDivElement | null>(null);
    const triggerRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                tooltipRef.current &&
                !tooltipRef.current.contains(event.target as Node) &&
                triggerRef.current &&
                !triggerRef.current.contains(event.target as Node)
            ) {
                setIsVisible(false);
            }
        };

        if (trigger === 'click' && isVisible) {
            document.addEventListener('mousedown', handleClickOutside);
        }

        return () => {
            if (trigger === 'click') {
                document.removeEventListener('mousedown', handleClickOutside);
            }
        };
    }, [isVisible, trigger]);

    const handleMouseDown = (event: React.MouseEvent) => {
        event.stopPropagation();
    };

    const toggleVisibility = () => {
        if (trigger === 'click') {
            setIsVisible((prev) => !prev);
        }
    };

    const getContentStyles = () => {
        switch (placement) {
            case 'top':
                return {
                    className: 'bottom-full left-1/2 transform -translate-x-1/2 mb-2',
                    style: {},
                };
            case 'bottom':
                return {
                    className: 'top-full left-1/2 transform -translate-x-1/2 mt-2',
                    style: {},
                };
            case 'left-top':
                return {
                    className: 'right-full mr-2',
                    style: { bottom: verticalOffsetValue },
                };
            case 'left-center':
                return {
                    className: 'right-full top-1/2 transform -translate-y-1/2 mr-2',
                    style: {},
                };
            case 'left-bottom':
                return {
                    className: 'right-full mr-2',
                    style: { top: verticalOffsetValue },
                };
            case 'right-top':
                return {
                    className: 'left-full mr-2',
                    style: { bottom: verticalOffsetValue },
                };
            case 'right-center':
                return {
                    className: 'left-full top-1/2 transform -translate-y-1/2 ml-2',
                    style: {},
                };
            case 'right-bottom':
                return {
                    className: 'left-full mr-2',
                    style: { top: verticalOffsetValue },
                };
            default:
                return { className: '', style: {} };
        }
    };

    const { className: contentClass, style: contentStyle } = getContentStyles();

    const getArrowBoxStyles = () => {
        const baseArrowBoxClass = 'absolute overflow-hidden';

        const horizontalRectangleClass = 'w-6 h-3';
        const verticalRectangleClass = 'w-3 h-6';
        const recatangleOffset = '-12px';

        const horizontalCenterClass = 'left-1/2 transform -translate-x-1/2';
        const verticalCenterClass = 'top-1/2 transform -translate-y-1/2';

        switch (placement) {
            case 'top':
                return {
                    className: `${baseArrowBoxClass} ${horizontalRectangleClass} ${horizontalCenterClass}`,
                    style: { bottom: recatangleOffset },
                };
            case 'bottom':
                return {
                    className: `${baseArrowBoxClass} ${horizontalRectangleClass} ${horizontalCenterClass}`,
                    style: { top: recatangleOffset },
                };
            case 'left-top':
                return {
                    className: `${baseArrowBoxClass} ${verticalRectangleClass} bottom-0`,
                    style: { right: recatangleOffset },
                };
            case 'left-center':
                return {
                    className: `${baseArrowBoxClass} ${verticalRectangleClass} ${verticalCenterClass}`,
                    style: { right: recatangleOffset },
                };
            case 'left-bottom':
                return {
                    className: `${baseArrowBoxClass} ${verticalRectangleClass} top-0`,
                    style: { right: recatangleOffset },
                };
            case 'right-top':
                return {
                    className: `${baseArrowBoxClass} ${verticalRectangleClass} bottom-0`,
                    style: { left: recatangleOffset },
                };
            case 'right-center':
                return {
                    className: `${baseArrowBoxClass} ${verticalRectangleClass} ${verticalCenterClass}`,
                    style: { left: recatangleOffset },
                };
            case 'right-bottom':
                return {
                    className: `${baseArrowBoxClass} ${verticalRectangleClass} top-0`,
                    style: { left: recatangleOffset },
                };
            default:
                return { className: '', style: {} };
        }
    };

    const { className: arrowBoxClass, style: arrowBoxStyle } = getArrowBoxStyles();

    const getArrowClasses = () => {
        const baseArrowClass = 'w-2 h-2 bg-background-mid rotate-45 absolute shadow-tooltip';

        const leftPositionClass = 'right-[8px] top-[8px]';
        const rightPositionClass = 'right-[-4px] top-[8px]';

        switch (placement) {
            case 'top':
                return `${baseArrowClass} right-[8px] top-[-4px]`;
            case 'bottom':
                return `${baseArrowClass} right-[8px] top-[8px]`;
            case 'left-top':
                return `${baseArrowClass} ${leftPositionClass}`;
            case 'left-center':
                return `${baseArrowClass} ${leftPositionClass}`;
            case 'left-bottom':
                return `${baseArrowClass} ${leftPositionClass}`;
            case 'right-top':
                return `${baseArrowClass} ${rightPositionClass}`;
            case 'right-center':
                return `${baseArrowClass} ${rightPositionClass}`;
            case 'right-bottom':
                return `${baseArrowClass} ${rightPositionClass}`;
            default:
                return '';
        }
    };

    return (
        <div data-testid={testIds.main} className='relative inline-block'>
            {/* biome-ignore lint/a11y/useKeyWithClickEvents: Not needed */}
            <div
                data-testid={testIds.trigger}
                ref={triggerRef}
                onClick={toggleVisibility}
                onMouseEnter={() => trigger === 'hover' && setIsVisible(true)}
                onMouseLeave={() => trigger === 'hover' && setIsVisible(false)}
            >
                {children}
            </div>

            {isVisible && (
                <div
                    data-testid={testIds.content}
                    ref={tooltipRef}
                    className={`absolute z-[99999] rounded-4 p-2 shadow-tooltip ${contentClass} ${className}`}
                    style={contentStyle}
                    onMouseDown={handleMouseDown}
                >
                    <div
                        data-testid={testIds.arrowBox}
                        className={arrowBoxClass}
                        style={arrowBoxStyle}
                    >
                        <div data-testid={testIds.arrow} className={getArrowClasses()} />
                    </div>
                    {content}
                </div>
            )}
        </div>
    );
};
