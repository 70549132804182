/*
 * Unpublished work. Copyright 2025 Siemens
 *
 * This material contains trade secrets or otherwise confidential information
 * owned by Siemens Industry Software Inc. or its affiliates (collectively,
 * "SISW"), or its licensors. Access to and use of this information is strictly
 * limited as set forth in the Customer's applicable agreements with SISW.
 */
import React from 'react';
import { usePluginRoot } from 'webComponent/hooks';

import { SearchIcon } from 'assets';
import { DropDownArrowIcon } from 'assets/DropDownArrowIcon';
import { Checkbox } from 'components/atoms/Checkbox';

const testIds = {
    checkItemCheckbox: 'dropdown-check-item-checkbox',
    checkItemLabel: 'dropdown-check-item-label',
    selectItemLabel: 'dropdown-select-item-label',
    input: 'dropdown-input',
    render: 'dropdown-render',
    arrow: 'dropdown-arrow',
    searchInput: 'dropdown-search-input',
    listContainer: 'dropdown-list-container',
    list: 'dropdown-list',
    searchIcon: 'dropdown-search-icon',
};

export { testIds as dropDownTestIds };

interface IDropdownSelectItemProps {
    children?: string;
    selected?: boolean;
}

export const DropdownSelectItem = ({
    selected,
    onClick,
    ...props
}: IDropdownSelectItemProps &
    Omit<React.HTMLAttributes<HTMLLIElement>, 'className' | 'aria-selected' | 'children'>) => (
    <li
        {...props}
        aria-selected={selected ?? false}
        className={
            /* prettier-ignore */
            /* eslint-disable prettier/prettier */
            'min-h-8 cursor-pointer bg-table-cell_background1 p-2 text-notation text-text-default ' +
            'hover:bg-table-cell_background_hover ' +
            'aria-selected:bg-table-cell_background_selected'
            /* eslint-enable prettier/prettier */
        }
        onClick={(e) => {
            onClick?.(e);
            e.target.dispatchEvent(
                new CustomEvent('dropdownSelectItemSelected', { bubbles: true }),
            );
        }}
    >
        <span data-testid={testIds.selectItemLabel} className='line-clamp-1'>
            {props.children}
        </span>
    </li>
);

interface IDropdownCheckItemProps {
    children?: string;
    checked?: boolean;
    onChange?: () => void;
}

export const DropdownCheckItem = ({
    checked,
    onChange,
    ...props
}: IDropdownCheckItemProps &
    Omit<React.HTMLAttributes<HTMLLIElement>, 'className' | 'children' | 'onChange'>) => (
    <li
        {...props}
        className='flex items-center bg-checkbox-check_default px-2 py-1 hover:bg-command_bar-background_pressedselected'
    >
        <Checkbox checked={checked} onChange={onChange} data-testid={testIds.checkItemCheckbox} />
        <label
            className='ml-1 line-clamp-1 text-notation text-text-default'
            data-testid={testIds.checkItemLabel}
        >
            {props.children}
        </label>
    </li>
);

interface IDropdownProps {
    render?: string;
    enableSearch?: boolean;
    searchValue?: string;
    onSearchValueChange?: React.ChangeEventHandler<HTMLInputElement>;
    onClose?: () => void;
}

export const Dropdown = ({
    children,
    render,
    enableSearch,
    searchValue,
    onSearchValueChange,
    onClose,
    ...props
}: IDropdownProps & Omit<React.HTMLAttributes<HTMLDivElement>, 'className'>) => {
    const [isOpen, setIsOpen] = React.useState(false);

    const root = usePluginRoot();
    const containerRef = React.useRef<HTMLDivElement>(null);
    const listContainerRef = React.useRef<HTMLDivElement>(null);

    const hasItems = React.useMemo(() => React.Children.count(children), [children]);

    const open = () => setIsOpen(true);
    const close = () => {
        setIsOpen(false);
        onClose?.();
    };

    const toggleIsOpen = () => (isOpen ? close() : open());

    const dropdownSelectItemSelectedHandler = (e: CustomEvent) => {
        e.stopPropagation();
        close();
    };

    const clickOutsideHandler = (e: MouseEvent) => {
        if (!containerRef.current?.contains(e.target as Node)) {
            close();
        }
    };

    React.useEffect(() => {
        root.addEventListener('mousedown', clickOutsideHandler);
        containerRef.current?.addEventListener(
            'dropdownSelectItemSelected',
            dropdownSelectItemSelectedHandler as EventListener,
        );

        return () => {
            root.removeEventListener('mousedown', clickOutsideHandler);
        };
    }, []);

    React.useEffect(() => {
        if (listContainerRef.current) {
            listContainerRef.current.scrollTop = 0;
        }
    }, [isOpen]);

    return (
        <div {...props} className='relative' ref={containerRef}>
            <div
                className={
                    /* prettier-ignore */
                    /* eslint-disable prettier/prettier */
                    'z-10 flex cursor-pointer rounded-2 bg-input-background p-2 ' +
                    'outline outline-1 -outline-offset-1 outline-input-border_default_hover ' +
                    'hover:outline-2 hover:-outline-offset-2 hover:outline-input-border_active ' +
                    'focus:outline-2 focus:-outline-offset-2 focus:outline-input-border_active ' +
                    'aria-expanded:outline-2 aria-expanded:-outline-offset-2 aria-expanded:outline-input-border_active'
                    /* eslint-enable prettier/prettier */
                }
                onClick={toggleIsOpen}
                tabIndex={0}
                aria-expanded={isOpen}
                data-testid={testIds.input}
            >
                <div
                    className='line-clamp-1 text-notation text-text-default'
                    data-testid={testIds.render}
                >
                    {render}
                </div>

                <DropDownArrowIcon
                    data-testid={testIds.arrow}
                    aria-expanded={isOpen}
                    className='ml-auto fill-icon-default aria-expanded:rotate-180'
                />
            </div>

            <div
                data-testid={testIds.listContainer}
                aria-expanded={isOpen}
                className={
                    /* prettier-ignore */
                    /* eslint-disable prettier/prettier */
                    'absolute z-20 mt-0.5 hidden w-full rounded-3 bg-input-background ' +
                    'shadow-dropdown outline outline-1 -outline-offset-1 ' +
                    'outline-background-mid aria-expanded:visible aria-expanded:block'
                    /* eslint-enable prettier/prettier */
                }
                ref={listContainerRef}
            >
                {enableSearch && (
                    <div className={`px-2 pt-2 ${hasItems ? 'pb-0' : 'pb-2'}`}>
                        <label className='group/label relative block text-notation'>
                            <SearchIcon
                                data-testid={testIds.searchIcon}
                                className='absolute right-2 top-1 cursor-text fill-icon-default'
                            />
                            <input
                                data-testid={testIds.searchInput}
                                value={searchValue}
                                onChange={onSearchValueChange}
                                placeholder='Search'
                                type='text'
                                className={
                                    /* prettier-ignore */
                                    /* eslint-disable prettier/prettier */
                                    'w-full rounded-2 border-none py-1 pl-2 pr-8 text-notation italic text-text-hint_text bg-input-background ' +
                                    'outline outline-1 -outline-offset-1 outline-input-border_disabled ' +
                                    'focus:outline-2 focus:-outline-offset-2 focus:outline-input-border_disabled focus:ring-0 ' +
                                    'group-hover/label:outline-2 group-hover/label:-outline-offset-2 group-hover/label:outline-input-border_disabled'
                                    /* eslint-enable prettier/prettier */
                                }
                            />
                        </label>
                    </div>
                )}

                <ul
                    className='peer max-h-40 overflow-x-hidden rounded-b-3'
                    data-testid={testIds.list}
                >
                    {children}
                </ul>
            </div>
        </div>
    );
};
