/*
 * Unpublished work. Copyright 2025 Siemens
 *
 * This material contains trade secrets or otherwise confidential information
 * owned by Siemens Industry Software Inc. or its affiliates (collectively,
 * "SISW"), or its licensors. Access to and use of this information is strictly
 * limited as set forth in the Customer's applicable agreements with SISW.
 */
import type React from 'react';
import { useAppViewPort } from 'webComponent/hooks';

import { DownloadButton } from 'components/atoms/DownloadButton';
import { DragButton } from 'components/molecules/DragButton';
import { EcadAvailabilityInfo } from 'components/molecules/EcadAvailabilityInfo';
import { SourceAndDescriptionTooltip } from 'components/molecules/SourceAndDescriptionTooltip';
import { SourceElement } from 'components/molecules/SourceElement';
import type { IPluginData } from 'types/PluginData';

const testIds = {
    dragButtonContainer: 'component-vertical-info-drag-button-container',
    manufacturerContainer: 'component-vertical-info-manufacturer-container',
    mfrPartNumber: 'component-vertical-info-mfr-part-number',
    manufacturerName: 'component-vertical-info-manufacturer-name',
    tooltipContainer: 'component-vertical-info-tooltip-container',
    sourceContainer: 'component-vertical-info-source-container',
    descriptionContainer: 'component-vertical-info-description-container',
    description: 'component-vertical-info-description',
    availabilityContainer: 'component-vertical-info-availability-container',
    downloadButtonContainer: 'component-vertical-info-download-button-container',
} as const;

export { testIds as componentVerticalInfoTestIds };

export interface IComponentVerticalInfoProps {
    data: IPluginData;
    hideRequestButton?: boolean;
    hideDescription?: boolean;
    hideSource?: boolean;
}

export const ComponentVerticalInfo = ({
    data,
    hideRequestButton,
    hideDescription,
    hideSource,
    ...props
}: IComponentVerticalInfoProps &
    Omit<React.HTMLAttributes<HTMLDivElement>, 'children' | 'className'>) => {
    const { isSm, isMd, isLg } = useAppViewPort();
    return (
        <div {...props} className='flex items-center justify-center text-text-default'>
            <div className='p-2' data-testid={testIds.dragButtonContainer}>
                <DragButton data={data} />
            </div>

            <div
                className='grid w-[200px] flex-shrink-0 grid-flow-col grid-rows-[16px_13px] justify-between gap-x-2 px-2 pt-0.5'
                data-testid={testIds.manufacturerContainer}
            >
                <div
                    className='truncate text-left text-notation font-semibold'
                    data-testid={testIds.mfrPartNumber}
                >
                    {data.mfrPartNumber}
                </div>
                <div
                    className='truncate whitespace-nowrap text-left text-icon-description'
                    data-testid={testIds.manufacturerName}
                >
                    {data.manufacturer.name}
                </div>

                <div
                    className={`row-span-2 place-self-center ${isLg ? 'hidden' : ''}`}
                    data-testid={testIds.tooltipContainer}
                >
                    <SourceAndDescriptionTooltip
                        source={data.dataAvailability.source}
                        description={data.description}
                        tooltipPlacement='left-bottom'
                        verticalOffsetValue={-4}
                    />
                </div>
            </div>

            {!hideSource && (
                <div
                    className={`flex w-[100px] flex-shrink-0 items-center justify-center px-2 ${isSm || isMd ? 'hidden' : ''}`}
                    data-testid={testIds.sourceContainer}
                >
                    <SourceElement source={data.dataAvailability.source} />
                </div>
            )}

            {!hideDescription && (
                <div
                    className={`description flex flex-grow items-center px-2 ${isSm ? 'hidden' : ''}`}
                    data-testid={testIds.descriptionContainer}
                >
                    <div className='line-clamp-2 text-notation' data-testid={testIds.description}>
                        {data.description}
                    </div>
                </div>
            )}

            {!hideRequestButton && (
                <div
                    className='flex w-[92px] flex-shrink-0 items-center justify-center'
                    data-testid={testIds.availabilityContainer}
                >
                    <EcadAvailabilityInfo componentData={data} tooltipPlacement={'left-bottom'} />
                </div>
            )}

            <div className='p-2' data-testid={testIds.downloadButtonContainer}>
                <DownloadButton datasheetUrl={data.datasheet} />
            </div>
        </div>
    );
};
