/*
 * Unpublished work. Copyright 2025 Siemens
 *
 * This material contains trade secrets or otherwise confidential information
 * owned by Siemens Industry Software Inc. or its affiliates (collectively,
 * "SISW"), or its licensors. Access to and use of this information is strictly
 * limited as set forth in the Customer's applicable agreements with SISW.
 */
import { useDownloadContext } from 'context/download';
import { siemensAnalyticsEvents, siemensAnalyticsService } from 'services/SiemensAnalyticsService';
import { usePQPortalRootUrl } from 'webComponent/hooks';

import { usePostInitCacheForDownloadMutation } from 'api';
import type { IInitCacheForDownloadRequest, IdndKeys } from 'types/Download';
import type { IPluginData } from 'types/PluginData';

export interface IDragDiagnostics {
    downloadKey?: string;
}

const DEBUG = false;

const createQueryObj = (partId: string) => {
    const queryObj: IInitCacheForDownloadRequest = {
        partToPartition: [
            {
                dataSource: 'Supplyframe',
                partId: partId,
                partition: '',
            },
        ],
        flow: 'PADS PRO',
        uniqueId:
            Math.random().toString(36).substring(2, 15) +
            Math.random().toString(36).substring(2, 15),
    };
    return queryObj;
};

const encodeUrl = (url: string, data: string) => `${url}${encodeURIComponent(data)}`;

const createDragData = (portalRootUrl: string, queryObj: IInitCacheForDownloadRequest) => {
    const dndObj: IdndKeys = {
        ps: [
            {
                x: 'Supplyframe',
                y: queryObj.partToPartition[0].partId,
                z: '',
            },
        ],
        f: 'PADS PRO',
        c: queryObj.downloadKey,
        k: queryObj.uniqueId,
    };
    DEBUG &&
        console.debug('createDragData', {
            apiRootUrl: portalRootUrl,
            dndObj: JSON.stringify(dndObj),
        });
    const callEdx = encodeUrl(
        `${portalRootUrl}/api/v2/download/parts/edx?x=`,
        JSON.stringify(dndObj),
    );
    const callLog = encodeUrl(
        `${portalRootUrl}/api/v2/download/tool/spn/report?k=`,
        queryObj.uniqueId,
    );

    return JSON.stringify({
        source: 'PartQuest',
        urls: [{ edx: callEdx, log: callLog }],
        v2: {
            source: 'PartQuest',
            urls: [
                {
                    edx: {
                        url: callEdx,
                        headers: [{ name: 'Cookie', value: '' }],
                    },
                    log: {
                        url: callLog,
                    },
                },
            ],
        },
    });
};

const createAnalyticsEvent = (data: IPluginData) =>
    siemensAnalyticsEvents.createDownloadEvent({
        'CAD Source': data.dataAvailability.source,
        'Part Category': data.category.name,
        Supplier: data.manufacturer.name,
        'Supplier Part Number': data.mfrPartNumber,
    });

export const useDragButton = (data: IPluginData) => {
    if (data === undefined) {
        throw new Error('data is required for useDragButton');
    }
    const pQPortalRootUrl = usePQPortalRootUrl();
    if (pQPortalRootUrl === undefined) {
        throw new Error('pQPortalRootUrl is required for useDragButton');
    }
    const initCacheForDownloadMutation = usePostInitCacheForDownloadMutation();
    const downloadContext = useDownloadContext();
    if (downloadContext === undefined) {
        throw new Error('downloadContext is required for useDragButton');
    }
    const queryObj = createQueryObj(data.supplierPartNumber);
    const analyticsEvent = createAnalyticsEvent(data);

    const handleDragStart = (event: React.DragEvent<HTMLDivElement>) => {
        const downloadKey = downloadContext.dischargeKeysPool();
        queryObj.downloadKey = downloadKey;

        if (!downloadKey) {
            console.warn('useDragButton.handleDragStart: downloadKey not available', {
                queryObj,
            });
            return;
        }

        (event.nativeEvent as IDragDiagnostics).downloadKey = downloadKey;
        const dragData = createDragData(pQPortalRootUrl, queryObj);
        event.dataTransfer.setData('text', dragData);

        initCacheForDownloadMutation.mutate(queryObj);

        siemensAnalyticsService.logEvent(analyticsEvent);
    };

    return {
        handleDragStart,
    };
};
