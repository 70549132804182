/*
 * Unpublished work. Copyright 2025 Siemens
 *
 * This material contains trade secrets or otherwise confidential information
 * owned by Siemens Industry Software Inc. or its affiliates (collectively,
 * "SISW"), or its licensors. Access to and use of this information is strictly
 * limited as set forth in the Customer's applicable agreements with SISW.
 */
export const CautionIcon16 = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        width='16'
        height='16'
        viewBox='0 0 16 16'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        {...props}
    >
        <title>Caution Icon</title>
        <g clipPath='url(#clip0_7189_33869)'>
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M14.8018 15.4856C14.9823 15.4711 15.1578 15.4172 15.3159 15.3274C15.5031 15.2211 15.6596 15.0681 15.7701 14.8833C15.8806 14.6986 15.9415 14.4885 15.9469 14.2733C15.9523 14.0582 15.9021 13.8452 15.801 13.6552L9.07285 1.15632L9.07236 1.15542C8.96525 0.957397 8.80671 0.791863 8.61332 0.676433C8.41992 0.560997 8.19892 0.500001 7.97365 0.500001C7.74837 0.500001 7.52737 0.560997 7.33397 0.676433C7.14058 0.791863 6.98204 0.957397 6.87493 1.15542L6.87439 1.1564L0.147411 13.6591L0.146269 13.6612C0.0452043 13.8512 -0.00502059 14.0642 0.000393237 14.2793C0.00580709 14.4945 0.06668 14.7046 0.177191 14.8893C0.287708 15.0741 0.444143 15.2271 0.631395 15.3334C0.818655 15.4397 1.03029 15.4957 1.24567 15.4957L14.7016 15.4957C14.7359 15.4957 14.7694 15.4922 14.8018 15.4856Z'
                fill='var(--icon-error)'
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M6.47656 12.496C6.47656 11.6703 7.14475 10.9961 7.975 10.9961C8.80526 10.9961 9.47345 11.6703 9.47345 12.496C9.47345 13.3216 8.80526 13.9958 7.975 13.9958C7.14475 13.9958 6.47656 13.3216 6.47656 12.496Z'
                fill='var(--icon-default_inverted)'
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M6.97656 10.4964L6.97656 4.49805L8.97656 4.49805L8.97656 10.4964L6.97656 10.4964Z'
                fill='var(--icon-default_inverted)'
            />
        </g>
        <defs>
            <clipPath id='clip0_7189_33869'>
                <rect width='16' height='16' fill='white' />
            </clipPath>
        </defs>
    </svg>
);
