/*
 * Unpublished work. Copyright 2025 Siemens
 *
 * This material contains trade secrets or otherwise confidential information
 * owned by Siemens Industry Software Inc. or its affiliates (collectively,
 * "SISW"), or its licensors. Access to and use of this information is strictly
 * limited as set forth in the Customer's applicable agreements with SISW.
 */
import type { ComponentProps } from 'react';

import { SessionExpirationIcon } from 'assets/SessionExpirationIcon';
import { Message } from 'components/atoms/Message';

export const SessionExpirationMessageTestIds = {
    icon: 'session-expiration-message-icon',
    contentArea: 'session-expiration-message-content-area',
} as const;

export const SessionExpirationMessage = (
    props: Omit<ComponentProps<typeof Message>, 'children'>,
) => {
    return (
        <Message {...props}>
            <div
                className='flex h-screen w-screen flex-col items-center justify-center text-text-hint_text_empty_screen'
                data-testid={SessionExpirationMessageTestIds.contentArea}
            >
                <SessionExpirationIcon
                    className='mb-1'
                    data-testid={SessionExpirationMessageTestIds.icon}
                />
                <h2 className='mt-2 text-h4 font-semibold'>Session Expired</h2>
                <p className='mt-0.5 text-center text-p font-normal'>
                    Restart PartQuest Search in order to <br />
                    continue working with it.
                </p>
            </div>
        </Message>
    );
};
