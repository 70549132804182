/*
 * Unpublished work. Copyright 2025 Siemens
 *
 * This material contains trade secrets or otherwise confidential information
 * owned by Siemens Industry Software Inc. or its affiliates (collectively,
 * "SISW"), or its licensors. Access to and use of this information is strictly
 * limited as set forth in the Customer's applicable agreements with SISW.
 */
import React, { createContext, useState } from 'react';

import { SessionExpirationMessage } from 'components/molecules/SessionExpirationMessage/SessionExpirationMessage';

interface SessionExpirationContextType {
    sessionExpired: boolean;
    setSessionExpired: (expired: boolean) => void;
}

export const SessionExpirationContext = createContext<SessionExpirationContextType | undefined>(
    undefined,
);

export const SessionExpirationContextTestIds = {
    message: 'session-expiration-message',
};

export const SessionExpirationProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
    const [sessionExpired, setSessionExpired] = useState(false);

    return (
        <SessionExpirationContext.Provider value={{ sessionExpired, setSessionExpired }}>
            {sessionExpired ? (
                <SessionExpirationMessage data-testid={SessionExpirationContextTestIds.message} />
            ) : (
                children
            )}
        </SessionExpirationContext.Provider>
    );
};
