/*
 * Unpublished work. Copyright 2025 Siemens
 *
 * This material contains trade secrets or otherwise confidential information
 * owned by Siemens Industry Software Inc. or its affiliates (collectively,
 * "SISW"), or its licensors. Access to and use of this information is strictly
 * limited as set forth in the Customer's applicable agreements with SISW.
 */
import React from 'react';
import { useAppViewPort } from 'webComponent/hooks';

import { Tooltip } from 'components/molecules/Tooltip';
import type { IPart } from 'types/DistributionChainInfo';

import { TooltipIcon } from '../../../assets/TooltipIcon';

const testIds = {
    main: 'supply-chain-table-view-part-info-tooltip-main',
    icon: 'supply-chain-table-view-part-info-tooltip-icon',
    distributorPartNumber: 'supply-chain-table-view-part-info-tooltip-distributor-part-number',
    manufacturer: 'supply-chain-table-view-part-info-tooltip-manufacturer',
    description: 'supply-chain-table-view-part-info-tooltip-description',
    minimumOrderQuantity: 'supply-chain-table-view-part-info-tooltip-minimum-order-quantity',
    leadTime: 'supply-chain-table-view-part-info-tooltip-lead-time',
    containerType: 'supply-chain-table-view-part-info-tooltip-container-type',
};

export { testIds as SupplyChainTableViewPartInfoTooltipTestIds };

export const PartInfoTooltip = ({ data }: { data: IPart }) => {
    const { isSm, isMd, isLg } = useAppViewPort();

    const { showDescription, tooltipSmClass, tooltipMdClass } = React.useMemo(() => {
        const hasDescription =
            data.description !== undefined ||
            data.minimumOrderQuantity !== undefined ||
            data.leadTime !== undefined ||
            data.containerType !== undefined;

        const hasOtherFieldsSm =
            data.distributorPartNumber !== undefined || data.manufacturerName !== undefined;
        const hasOtherFieldsMd = data.manufacturerName !== undefined;

        const smClass = hasDescription || hasOtherFieldsSm ? 'block' : 'hidden';
        const mdClass = hasDescription || hasOtherFieldsMd ? 'block' : 'hidden';

        return {
            showDescription: hasDescription,
            tooltipSmClass: smClass,
            tooltipMdClass: mdClass,
        };
    }, [data]);

    return (
        <div
            data-testid={testIds.main}
            className={`ml-auto ${isSm ? tooltipSmClass : ''} ${isMd ? tooltipMdClass : ''} ${isLg ? 'hidden' : ''}`}
        >
            <Tooltip
                className='w-[146px] bg-background-mid p-0 text-notation text-text-default'
                content={
                    <div className='font-normal'>
                        {data.distributorPartNumber !== undefined && (
                            <div className={`${isMd ? 'hidden' : ''}`}>
                                <span className='font-semibold'>DISTI#:</span>
                                <div
                                    data-testid={testIds.distributorPartNumber}
                                    className='mt-1 break-all'
                                >
                                    {data.distributorPartNumber}
                                </div>
                            </div>
                        )}

                        {data.manufacturerName !== undefined && (
                            <div className={`${isSm ? 'mt-2' : ''}`}>
                                <span className='font-semibold'>Manufacturer:</span>
                                <div data-testid={testIds.manufacturer} className='mt-1'>
                                    {data.manufacturerName}
                                </div>
                            </div>
                        )}

                        {showDescription && (
                            <div className='mt-2'>
                                <span className='font-semibold'>Description:</span>
                                <div className='grid grid-cols-1 gap-y-2'>
                                    {data.description !== undefined && (
                                        <div data-testid={testIds.description}>
                                            {data.description}
                                        </div>
                                    )}

                                    {data.minimumOrderQuantity !== undefined && (
                                        <div>
                                            <span>Min Qty:</span>{' '}
                                            <span
                                                data-testid={testIds.minimumOrderQuantity}
                                                className='font-semibold text-text-positive'
                                            >
                                                {data.minimumOrderQuantity}
                                            </span>
                                        </div>
                                    )}

                                    {data.leadTime !== undefined && (
                                        <div>
                                            <span>Lead time:</span>{' '}
                                            <span
                                                data-testid={testIds.leadTime}
                                                className='font-semibold text-text-positive'
                                            >
                                                {data.leadTime}
                                            </span>
                                        </div>
                                    )}

                                    {data.containerType !== undefined && (
                                        <div>
                                            <span>Lead time:</span>{' '}
                                            <span
                                                data-testid={testIds.containerType}
                                                className='font-semibold text-text-positive'
                                            >
                                                {data.containerType}
                                            </span>
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                }
                placement='right-center'
            >
                <TooltipIcon data-testid={testIds.icon} />
            </Tooltip>
        </div>
    );
};
