/*
 * Unpublished work. Copyright 2025 Siemens
 *
 * This material contains trade secrets or otherwise confidential information
 * owned by Siemens Industry Software Inc. or its affiliates (collectively,
 * "SISW"), or its licensors. Access to and use of this information is strictly
 * limited as set forth in the Customer's applicable agreements with SISW.
 */
import React from 'react';

import { EmptyMessage } from 'components/molecules/EmptyMessage';
import { ErrorMessage } from 'components/molecules/ErrorMessage';
import { FetchMessage } from 'components/molecules/FetchMessage';
import { useSupplyChainQuery } from 'components/organisms/SupplyChainDetails/useSupplyChainQuery';
import { SupplyChainExtendedRowView } from 'components/organisms/SupplyChainExtendedRowView';
import {
    type ISupplyChainFilteringState,
    SupplyChainFilteringForm,
} from 'components/organisms/SupplyChainFilteringForm';
import { SupplyChainTableView } from 'components/organisms/SupplyChainTableView';
import type { IViewSettings } from 'components/organisms/SupplyChainTableView/ViewSettingsTooltip';
import type { KeyedDistributionChainInfoType } from 'types/DistributionChainInfo';

const testIds = {
    main: 'supply-chain-drawer-filtering-main',
    filteringForm: 'supply-chain-drawer-filtering-form',
    pricingResultTable: 'supply-chain-drawer-pricing-result-table',
    pricingResultExtendedRows: 'supply-chain-drawer-pricing-result-extended-rows',
    fetchMessage: 'supply-chain-drawer-fetch-message',
    errorMessage: 'supply-chain-drawer-error-message',
    emptyMessage: 'supply-chain-drawer-empty-message',
} as const;

export { testIds as SupplyChainDetailsTestIds };

const PricingResult = ({
    data,
    drawerDetailsContainerRef,
}: {
    data: KeyedDistributionChainInfoType;
    drawerDetailsContainerRef: React.RefObject<HTMLDivElement>;
}) => {
    const [viewSettings, setViewSettings] = React.useState<IViewSettings>({
        tableBanding: false,
        rowBorder: false,
        grid: false,
        extendRows: false,
    });

    const toggleView = () =>
        setViewSettings({ ...viewSettings, extendRows: !viewSettings.extendRows });

    return !viewSettings.extendRows ? (
        <SupplyChainTableView
            data-testid={testIds.pricingResultTable}
            data={data}
            drawerDetailsContainerRef={drawerDetailsContainerRef}
            viewSettings={viewSettings}
            setViewSettings={setViewSettings}
        />
    ) : (
        <SupplyChainExtendedRowView
            data-testid={testIds.pricingResultExtendedRows}
            data={data}
            toggleView={toggleView}
        />
    );
};

interface ISupplyChainDetailsProps {
    manufacturerPartNumber: string;
}

export const SupplyChainDetails = ({
    manufacturerPartNumber,
    ...props
}: ISupplyChainDetailsProps &
    Omit<React.HTMLAttributes<HTMLDivElement>, 'children' | 'className'>) => {
    const [enableExactMatchesQuery, setEnableExactMatchesQuery] = React.useState(false);

    const [filters, setFilters] = React.useState<ISupplyChainFilteringState>({
        partDistributors: [],
        inStockOnly: false,
        exactMatchesOnly: false,
    });

    React.useEffect(() => {
        if (filters.exactMatchesOnly) {
            setEnableExactMatchesQuery(true);
        }
    }, [filters]);

    const {
        isAnyFetching,
        isCurrentError,
        isNotExactSuccess,
        filteredData,
        partDistributors,
        currencies,
        manufacturers,
    } = useSupplyChainQuery(manufacturerPartNumber, enableExactMatchesQuery, filters);

    const drawerDetailsContainerRef = React.useRef(null);

    const showFilteringForm = isNotExactSuccess;
    const showFetchMessage = isAnyFetching;
    const showErrorMessage = isCurrentError;
    const showEmptyMessage = !isAnyFetching && !isCurrentError && filteredData.length === 0;
    const showResults = !isAnyFetching && filteredData.length > 0;

    const messageHeightClass = showFilteringForm ? 'h-[calc(100%-108px)]' : 'h-full';

    return (
        <div
            data-testid={testIds.main}
            {...props}
            ref={drawerDetailsContainerRef}
            className='custom-scrollbar h-[calc(100%-40px)] overflow-y-auto'
        >
            {showFilteringForm && (
                <SupplyChainFilteringForm
                    data-testid={testIds.filteringForm}
                    partDistributors={partDistributors}
                    currencies={currencies}
                    manufacturers={manufacturers}
                    filters={filters}
                    setFilters={setFilters}
                />
            )}
            {showFetchMessage && (
                <FetchMessage data-testid={testIds.fetchMessage} className={messageHeightClass} />
            )}
            {showErrorMessage && (
                <ErrorMessage data-testid={testIds.errorMessage} className={messageHeightClass} />
            )}
            {showEmptyMessage && (
                <EmptyMessage data-testid={testIds.emptyMessage} className={messageHeightClass} />
            )}
            {showResults &&
                filteredData.map((dci) => (
                    <PricingResult
                        key={dci.distributor.key}
                        data={dci}
                        drawerDetailsContainerRef={drawerDetailsContainerRef}
                    />
                ))}
            <div className='h-24' /> {/* Empty space at the bottom for tooltip*/}
        </div>
    );
};
