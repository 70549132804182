/*
 * Unpublished work. Copyright 2025 Siemens
 *
 * This material contains trade secrets or otherwise confidential information
 * owned by Siemens Industry Software Inc. or its affiliates (collectively,
 * "SISW"), or its licensors. Access to and use of this information is strictly
 * limited as set forth in the Customer's applicable agreements with SISW.
 */
import type { ComponentProps } from 'react';
import { useAppViewPort } from 'webComponent/hooks';

import { TooltipIcon } from 'assets/TooltipIcon';
import { Tooltip, type TooltipPlacementType } from 'components/molecules/Tooltip';
import type { PluginSourceType } from 'types/PluginData';

interface ISourceAndDescriptionTooltipProps {
    source: PluginSourceType;
    description: string;
    tooltipPlacement?: TooltipPlacementType;
    verticalOffsetValue?: number;
}

const testIds = {
    main: 'source-and-description-tooltip-main',
    content: 'source-and-description-tooltip-content',
    icon: 'source-and-description-tooltip-icon',
    sourceContainer: 'source-and-description-tooltip-source-container',
    source: 'source-and-description-tooltip-source',
    descriptionContainer: 'source-and-description-tooltip-description-container',
    description: 'source-and-description-tooltip-description',
} as const;

export { testIds as SourceAndDescriptionTooltipTestIds };

export const SourceAndDescriptionTooltip = ({
    source,
    description,
    tooltipPlacement,
    verticalOffsetValue,
    ...props
}: ISourceAndDescriptionTooltipProps &
    Omit<ComponentProps<typeof Tooltip>, 'content' | 'className'>) => {
    const { isMd, isLg } = useAppViewPort();
    return (
        <Tooltip
            {...props}
            data-testid={testIds.main}
            className='border-0 bg-background-mid'
            content={
                <div
                    className='w-[162px] overflow-hidden text-notation text-text-default'
                    data-testid={testIds.content}
                >
                    <div data-testid={testIds.sourceContainer}>
                        <h3 className='font-bold'>Data Source:</h3>
                        <p data-testid={testIds.source}>{source}</p>
                    </div>

                    <div
                        data-testid={testIds.descriptionContainer}
                        className={`${isMd || isLg ? 'hidden' : ''}`}
                    >
                        <h3 className='mt-1 font-bold'>Description:</h3>
                        <p data-testid={testIds.description}>{description}</p>
                    </div>
                </div>
            }
            placement={tooltipPlacement}
            verticalOffsetValue={verticalOffsetValue}
        >
            <TooltipIcon data-testid={testIds.icon} className='cursor-pointer' />
        </Tooltip>
    );
};
