/*
 * Unpublished work. Copyright 2025 Siemens
 *
 * This material contains trade secrets or otherwise confidential information
 * owned by Siemens Industry Software Inc. or its affiliates (collectively,
 * "SISW"), or its licensors. Access to and use of this information is strictly
 * limited as set forth in the Customer's applicable agreements with SISW.
 */
import { useQuery } from '@tanstack/react-query';
import { SessionExpirationContext } from 'context/SessionExpirationContext';
import { useContext } from 'react';
import { useApiRootUrl, usePluginSessionToken } from 'webComponent/hooks';

import { IExtractAnalyticsInfoResponse } from 'types/ExtractAnalyticsInfo';

import { SessionExpiredError, api } from './apiHelper';
import apiRoutes from './apiRoutes';

export const useGetAnalyticsInfoQuery = () => {
    const apiRootUrl = useApiRootUrl();
    const sessionToken = usePluginSessionToken();
    const { setSessionExpired } = useContext(SessionExpirationContext) || {};

    return useQuery<IExtractAnalyticsInfoResponse>({
        queryKey: ['getExtractAnalyticsInfo'],
        queryFn: async ({ signal }) =>
            api.getJson<IExtractAnalyticsInfoResponse>(
                sessionToken!,
                apiRootUrl!,
                apiRoutes.getExtractAnalyticsInfo,
                undefined,
                signal,
            ),
        refetchOnWindowFocus: false,
        enabled: sessionToken !== undefined && apiRootUrl !== undefined,
        useErrorBoundary: true,
        cacheTime: Number.POSITIVE_INFINITY,
        staleTime: Number.POSITIVE_INFINITY,
        retry: (count, error) => {
            if (error instanceof SessionExpiredError) {
                if (setSessionExpired) {
                    setSessionExpired(true);
                }
                return false;
            }
            return count < 3;
        },
    });
};
