/*
 * Unpublished work. Copyright 2025 Siemens
 *
 * This material contains trade secrets or otherwise confidential information
 * owned by Siemens Industry Software Inc. or its affiliates (collectively,
 * "SISW"), or its licensors. Access to and use of this information is strictly
 * limited as set forth in the Customer's applicable agreements with SISW.
 */
import { useQuery } from '@tanstack/react-query';
import { SessionExpirationContext } from 'context/SessionExpirationContext';
import { useContext } from 'react';
import { useApiRootUrl, usePluginSessionToken } from 'webComponent/hooks';

import type {
    IDistributionChainInfoRequest,
    IDistributionChainInfoResponse,
} from 'types/DistributionChainInfo';

import { SessionExpiredError, api } from './apiHelper';
import apiRoutes from './apiRoutes';

export const useGetDistributionChainInfoKey = 'getDistributionChainInfo';

export const useGetDistributionChainInfo = (
    postData: IDistributionChainInfoRequest,
    isEnabled = true,
) => {
    const apiRootUrl = useApiRootUrl();
    const sessionToken = usePluginSessionToken();
    const { setSessionExpired } = useContext(SessionExpirationContext) || {};

    if (apiRootUrl === undefined) {
        throw new Error('apiRootUrl is required for useGetDistributionChainInfo');
    }

    if (sessionToken === undefined) {
        throw new Error('sessionToken is required for useGetDistributionChainInfo');
    }

    return useQuery<IDistributionChainInfoResponse>({
        queryKey: [
            useGetDistributionChainInfoKey,
            postData.manufacturerPartNumber,
            postData.exactMatchesOnly,
        ],
        queryFn: async ({ signal }) => {
            return api.postJson<IDistributionChainInfoResponse>(
                sessionToken,
                apiRootUrl,
                apiRoutes.postGetDistributionChainInfo,
                {
                    ...postData,
                },
                signal,
            );
        },
        cacheTime: 0,
        staleTime: 0,
        enabled: isEnabled,
        refetchOnWindowFocus: false,
        refetchOnMount: true,
        retry: (count, error) => {
            if (error instanceof SessionExpiredError) {
                if (setSessionExpired) {
                    setSessionExpired(true);
                }
                return false;
            }
            return count < 3;
        },
    });
};
