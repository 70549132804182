/*
 * Unpublished work. Copyright 2025 Siemens
 *
 * This material contains trade secrets or otherwise confidential information
 * owned by Siemens Industry Software Inc. or its affiliates (collectively,
 * "SISW"), or its licensors. Access to and use of this information is strictly
 * limited as set forth in the Customer's applicable agreements with SISW.
 */
import React, { type InputHTMLAttributes } from 'react';
import { tailwindCssUtil } from 'utils';

import { CautionIcon16 } from 'assets';

const BaseInput = React.forwardRef<
    HTMLInputElement,
    InputHTMLAttributes<HTMLInputElement> & { importantClasses?: string }
>((props, ref) => {
    const internalClassName = `w-full rounded-2 text-notation border-none
            outline outline-1 -outline-offset-1 outline-input-border_default_hover hover:outline-2
            focus:outline-2 focus:-outline-offset-2 focus:outline-input-border_active focus:ring-0
            bg-input-background text-text-default p-2
            ${props.className ?? ''}`;

    const { importantClasses, ...htmlProps } = props;

    const finalClassNameFn = () =>
        importantClasses
            ? tailwindCssUtil.customTwMerge(internalClassName, importantClasses)
            : internalClassName;
    const finalClassName = React.useMemo(finalClassNameFn, [internalClassName, importantClasses]);

    return <input {...htmlProps} className={finalClassName.trim()} type='text' ref={ref} />;
});

const testIds = {
    icon: 'normal-input-icon',
    input: 'normal-input-input',
    wrapper: 'normal-input-wrapper',
} as const;

export { testIds as InputTestIds };

export const NormalInput = React.forwardRef<
    HTMLInputElement,
    InputHTMLAttributes<HTMLInputElement> & { hasError?: boolean }
>((props, ref) => {
    const finalClassName = `${props.className ?? ''}`.trim();
    const { hasError, ...htmlProps } = props;
    return (
        <div data-testid={testIds.wrapper} className='relative flex'>
            <BaseInput
                {...htmlProps}
                data-testid={testIds.input}
                className={finalClassName}
                importantClasses={hasError ? 'bg-input-background_error' : ''}
                ref={ref}
            />
            {hasError && (
                <CautionIcon16
                    data-testid={testIds.icon}
                    className='absolute right-2 mt-2 fill-text-default'
                />
            )}
        </div>
    );
});

export const DisabledInput = React.forwardRef<
    HTMLInputElement,
    InputHTMLAttributes<HTMLInputElement>
>((props, ref) => {
    return (
        <BaseInput
            {...props}
            data-testid={testIds.input}
            disabled
            importantClasses={'bg-input-background_disabled text-text-disabled hover:outline-1'}
            ref={ref}
        />
    );
});
