/*
 * Unpublished work. Copyright 2025 Siemens
 *
 * This material contains trade secrets or otherwise confidential information
 * owned by Siemens Industry Software Inc. or its affiliates (collectively,
 * "SISW"), or its licensors. Access to and use of this information is strictly
 * limited as set forth in the Customer's applicable agreements with SISW.
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import { useContext } from 'react';
import { useApiRootUrl, usePluginSessionToken } from 'webComponent/hooks';

import type { DownloadKeyResponseType, IInitCacheForDownloadRequest } from 'types/Download';

import { SessionExpirationContext } from '../context/SessionExpirationContext';
import { SessionExpiredError, api } from './apiHelper';
import apiRoutes from './apiRoutes';

const DEBUG = false;

export const getDownloadKeyQueryKey = 'getDownloadKey';
export const useGetDownloadKeyQuery = (supplierPartNumber: string, enabled: boolean) => {
    DEBUG && enabled && console.debug('useGetDownloadKeyQuery', { enabled, supplierPartNumber });
    const apiRootUrl = useApiRootUrl();
    const sessionToken = usePluginSessionToken();
    const { setSessionExpired } = useContext(SessionExpirationContext) || {};

    if (apiRootUrl === undefined) {
        throw new Error('apiRootUrl is required for useGetDownloadKeyQuery');
    }

    if (sessionToken === undefined) {
        throw new Error('sessionToken is required for useGetDownloadKeyQuery');
    }

    return useQuery<DownloadKeyResponseType>({
        queryKey: [getDownloadKeyQueryKey, supplierPartNumber],
        queryFn: async ({ signal }) => {
            DEBUG && console.debug('useGetDownloadKeyQuery.queryFn', supplierPartNumber);
            return api.getJson<DownloadKeyResponseType>(
                sessionToken,
                apiRootUrl,
                apiRoutes.getDownloadKey,
                undefined,
                signal,
            );
        },
        enabled: enabled,
        cacheTime: 0,
        staleTime: 0,
        refetchOnWindowFocus: false,
        retry: (count, error) => {
            if (error instanceof SessionExpiredError) {
                if (setSessionExpired) {
                    setSessionExpired(true);
                }
                return false;
            }
            return count < 3;
        },
    });
};

export const useGetDownloadKeyMutation = () => {
    const apiRootUrl = useApiRootUrl();
    const sessionToken = usePluginSessionToken();
    const { setSessionExpired } = useContext(SessionExpirationContext) || {};

    if (apiRootUrl === undefined) {
        throw new Error('apiRootUrl is required for useGetDownloadKeyMutation');
    }

    if (sessionToken === undefined) {
        throw new Error('sessionToken is required for useGetDownloadKeyMutation');
    }

    return useMutation({
        mutationKey: [getDownloadKeyQueryKey],
        mutationFn: async () => {
            DEBUG && console.debug('useGetDownloadKeyMutation invoke download request');
            return api.getJson<DownloadKeyResponseType>(
                sessionToken,
                apiRootUrl,
                apiRoutes.getDownloadKey,
            );
        },
        cacheTime: 0,
        retry: (count, error) => {
            if (error instanceof SessionExpiredError) {
                if (setSessionExpired) {
                    setSessionExpired(true);
                }
                return false;
            }
            return count < 3;
        },
    });
};

export const postInitCacheForDownloadQueryKey = 'postInitCacheForDownload';

export const usePostInitCacheForDownloadMutation = () => {
    const apiRootUrl = useApiRootUrl();
    const sessionToken = usePluginSessionToken();
    const { setSessionExpired } = useContext(SessionExpirationContext) || {};

    if (apiRootUrl === undefined) {
        throw new Error('apiRootUrl is required for usePostInitCacheForDownloadMutation');
    }

    if (sessionToken === undefined) {
        throw new Error('sessionToken is required for usePostInitCacheForDownloadMutation');
    }

    return useMutation({
        mutationKey: [postInitCacheForDownloadQueryKey],
        mutationFn: async (postData: IInitCacheForDownloadRequest) => {
            DEBUG &&
                console.debug(
                    'usePostInitCacheForDownloadMutation.mutationFn - invoke init cache request',
                    JSON.stringify(postData),
                );
            return api.post(sessionToken, apiRootUrl, apiRoutes.postInitCacheForDownload, postData);
        },
        retry: (count, error) => {
            if (error instanceof SessionExpiredError) {
                if (setSessionExpired) {
                    setSessionExpired(true);
                }
                return false;
            }
            return count < 3;
        },
    });
};
