/*
 * Unpublished work. Copyright 2025 Siemens
 *
 * This material contains trade secrets or otherwise confidential information
 * owned by Siemens Industry Software Inc. or its affiliates (collectively,
 * "SISW"), or its licensors. Access to and use of this information is strictly
 * limited as set forth in the Customer's applicable agreements with SISW.
 */
import type { Table } from '@tanstack/react-table';

import { gridPagerSize } from 'const';
import type { IPluginData } from 'types/PluginData';

const testIds = {
    empty: 'pagination-empty',
    pagination: 'pagination',
    navigator: 'pagination-navigator',
    prevPage: 'pagination-prev-page',
    currentPage: 'pagination-current-page',
    nextPage: 'pagination-next-page',
} as const;

export { testIds as PaginationTestIds };

export interface IPaginationProps {
    table: Table<IPluginData>;
    totalParts: number;
    prevPage: () => void;
    nextPage: () => void;
}

export const Pagination = ({ table, totalParts, prevPage, nextPage }: IPaginationProps) => {
    const currentPage = table.getState().pagination.pageIndex + 1;
    const totalPages = Math.ceil((totalParts || 0) / table.getState().pagination.pageSize);
    const currentPageMax = Math.min(gridPagerSize * currentPage, totalParts);

    if (totalParts === 0) {
        return <div data-testid={testIds.empty} />;
    }
    return (
        <div
            data-testid={testIds.pagination}
            className='flex h-11 w-full items-center bg-background-inverted text-notation font-normal text-text-default'
        >
            <div className='flex-grow pl-4'>
                {`Showing ${gridPagerSize * currentPage - 24}-${currentPageMax} MPNs of ${totalParts} part(s)`}
            </div>
            <div
                className='flex select-none items-center border-paginator-border pr-4'
                data-testid={testIds.navigator}
            >
                <button
                    data-testid={testIds.prevPage}
                    onClick={prevPage}
                    className='border-red rounded-l-12 border border-r-0 border-paginator-border py-1 pl-2 pr-3 disabled:bg-paginator-background_disabled'
                    disabled={currentPage === 1}
                    type='button'
                >
                    <svg
                        width='7'
                        height='12'
                        viewBox='0 0 7 12'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                    >
                        <title>Previous</title>
                        <path d='M6.5 11.5L1 6L6.5 0.5' stroke='var(--icon-default)' />
                    </svg>
                </button>
                <div
                    data-testid={testIds.currentPage}
                    className='border border-paginator-border px-2 py-0.5'
                >
                    <span className='text-text-default_button_base'>{currentPage}</span> of{' '}
                    {totalPages}
                </div>
                <button
                    data-testid={testIds.nextPage}
                    onClick={nextPage}
                    className='rounded-r-12 border border-l-0 border-paginator-border py-1 pl-3 pr-2 disabled:bg-paginator-background_disabled'
                    disabled={currentPage === Math.ceil(totalParts / gridPagerSize)}
                    type='button'
                >
                    <svg
                        width='7'
                        height='12'
                        viewBox='0 0 7 12'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                    >
                        <title>Next</title>
                        <path d='M1 0.5L6.5 6L1 11.5' stroke='var(--icon-default)' />
                    </svg>
                </button>
            </div>
        </div>
    );
};
